define(['app', 'siteObj', 'columboConstants', 'columboService'], function(app, siteObj, columboConstants, columboService) {
  const ColumboGTM = function() {
    const module = {};

    const _init = function() {
      _fastenWidgets();
    };

    const _fastenWidgets = function() {
      app.subscribe('recordEvent', module.trackRecord);
      app.subscribe('widgetEvent', module.trackWidget);
    };

    // transform the data supplied from columbo into the format required for GTM
    const _trackWidget = function(data) {
      let widgetType = '';
      let widgetHref = '';
      let widgetDescription = '';
      if (data.widget.type) {
        widgetType = ' ' + data.widget.type;
      }
      if (data.widget.name) {
        widgetDescription = data.widget.name;
      }
      if (data.widget.link) {
        widgetHref = data.widget.link;
      }
      return module.columboService.gtmEventTrack({
        'event': 'elysiumEvent' + columboConstants.gtmEventNameAppend,
        'eventData': {
          eventCategory: 'Widget Track' + widgetType + columboConstants.gtmEventNameAppend,
          eventAction: widgetHref + columboConstants.gtmEventNameAppend,
          eventLabel: widgetDescription,
          eventPage: window.location.pathname
        }
      });
    };

    const _trackRecord = function(data) {
      const category = data.args[0];
      const action = data.args[1];
      const label = data.args[2];
      const labelValue = data.args[3];
      return module.columboService.gtmEventTrack({
        'event': 'elysiumEvent' + columboConstants.gtmEventNameAppend,
        'eventData': {
          eventCategory: category + columboConstants.gtmEventNameAppend,
          eventAction: action + columboConstants.gtmEventNameAppend,
          eventLabel: label + columboConstants.gtmEventNameAppend,
          eventLabelValue: labelValue,
          eventPage: window.location.pathname
        }
      });
    };

    module.trackWidget = _trackWidget;
    module.trackRecord = _trackRecord;
    module.init = _init;
    module.app = app;
    module.columboService = columboService;

    return module;
  };

  return ColumboGTM;

});
